// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  createcategorysuccess: false,
  updatecategorysuccess: false,
  deletecategorysuccess: false,
  loading: false,
  categories: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.CLEARSUCCESS:
      return {
        ...state,
        createcategorysuccess: false,
        updatecategorysuccess: false,
        deletecategorysuccess: false,
        loading: false,
      }
    case actionTypes.CREATECATEGORYSUCCESS:
        return {
          ...state,
          createcategorysuccess: action.payload
        };
    case actionTypes.UPDATECATEGORYSUCCESS:
        return {
          ...state,
          updatecategorysuccess: action.payload
        };
    case actionTypes.DELETECATEGORYSUCCESS:
        return {
            ...state,
            deletecategorysuccess: action.payload
          };
    case actionTypes.GETCATEGORYSUCCESS:
          return {
            ...state,
            categories: action.payload.services
          };
    default:
      return state;
  }
};

export default categoryReducer;
