import React from "react";




const Queuelayout = (props) => {
    return (
        <div style={{width: '100%', heght: '100vh', display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: 'rgb(255, 255, 255)'}}>
          {props.children}
        </div>
    )
}


export default Queuelayout;