// routing
import Routes from './routes';


// ==============================|| APP ||============================== //

const App = () => {

  return (
          <Routes />
  );
};

export default App;
