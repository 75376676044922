// action - state management
import { APP_URL } from '../../constants/apis';
import * as actionTypes from '../actions';
import axios from 'axios';

export const createCounter = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createcounter' });
  try {
    await axios.post(`${APP_URL}organization/counters/create`, data, {headers}).then(() => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATECOUNTERSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Counter created successfully' });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const createInsurance = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createcounter' });
  try {
    await axios.post(`${APP_URL}insurance`, data, {headers}).then(() => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATECOUNTERSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Insurance created successfully' });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const clearcountersuccess = () => async (dispatch) => {
  dispatch({type: actionTypes.CLEARSUCCESS})
}

export const setCurrentcounter = (id) => async (dispatch) => {
  dispatch({type: actionTypes.UPDATECURRENTCOUNTER, payload: id})
}

export const updateCounter = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatecounter' });
  try {
    await axios.post(`${APP_URL}organization/counters/update`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.UPDATECOUNTERSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Counter updated successfully' });

    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.UPDATECOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updateInsurance = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatecounter' });
  try {
    await axios.post(`${APP_URL}insurance/update`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.UPDATECOUNTERSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Insurance updated successfully' });

    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.UPDATECOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const daleteCounter = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'deletecounter' });
  try {
    await axios.post(`${APP_URL}organization/counters/delete`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.DELETECOUNTERSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Counter deleted successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.DELETECOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const deleteInsurance = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'deletecounter' });
  try {
    await axios.post(`${APP_URL}insurance/delete`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.DELETECOUNTERSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Insurance deleted successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.DELETECOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getCounters = (organization_id, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'getcounters' });
  try {
    await axios.get(`${APP_URL}organization/counters/list?organization=${organization_id}`, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETCOUNTERSUCCESS, payload: res.data });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETCOUNTERSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getInsurance = (organization_id, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'getcounters' });
  try {
    await axios.get(`${APP_URL}insurance/list?organization=${organization_id}`, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETINSURANCESUCCESS, payload: res.data });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETINSURANCESUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};


