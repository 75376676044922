import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';

function Login({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);

  console.log(customization.error)
  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
     
       {/* <h2 className='quicklinetitle'>Quickline</h2> */}
       <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
         <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 200}}/>
       <h2 className='quicklineminititle'>Log in here</h2>
       </div>
      
       {customization?.error?.type === 'login' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
          phonenumber: '+255',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          phonenumber: Yup.string()
            .required('Phone number is required')
            .test('is-valid-phone', 'Phone number is invalid', (value) => {
              return isValidPhoneNumber(value || '');
            }),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values) => {
          const removedPrefixPhoneNumber = values.phonenumber.substring(4);
          dispatch(login({ country_code: '255', mobile_number: removedPrefixPhoneNumber, password: values.password }));
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
              <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Phone number"
          aria-label="phonenumber"
          value={values.phonenumber}
          name="phonenumber"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.phonenumber && errors.phonenumber}
        />
        {touched.phonenumber && errors.phonenumber && (
         <Form.Control.Feedback type="invalid">
              {errors.phonenumber}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Password"
          type='password'
          name="password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label="password"
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.password && errors.password}
        />
          {touched.password && errors.password && (
         <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button disabled={auth.loading === 'login'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'login' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : 'Submit'}</Button>
           </Form>
         )}
      </Formik>
       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Login;