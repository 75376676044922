import moment from 'moment';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker, { DateObject } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"

function Visitsmodal({handleClose, show, onChangeDays, onClose, choosendates, removedate}) {

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <Form.Control
        type="text"
        placeholder="Choose days of visit"
        value={value}
         onChange={onChange}
        onFocus={onFocus}
        size="lg"
        width={'100%'}
      />
    )
  }

  console.log(choosendates);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>Add Visits</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody'>
          <Form className='forminput'>
          <Form.Group style={{width: '100%'}} className="mb-5 mt-3" controlId="exampleForm.ControlInput1">
          <DatePicker 
          value={null}
    onChange={onChangeDays}
    minDate={new Date()}
    multiple={false}
    render={<CustomInput />}
    // plugins={[
    //   <DatePanel
    //     position={"right"}
    //     sort="date"
    //   />]}
  />
  </Form.Group>
          </Form>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
           {choosendates?.length > 0 && choosendates?.map((choose) => {
            return (
              <div style={{width: '30%', marginBottom: 20, marginRight: '3.33%', position: 'relative', height: 30, borderRadius: 5, backgroundColor: '#d8e0f0', alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}>
              <span>{moment(`${choose.day}/${choose?.month?.number}/${choose.year}`, 'DD/MM/YYYY').format('DD/MM/YYYY')}</span>
              <div onClick={() => removedate(choose)} style={{width: 20, height: 20, borderRadius: 10, backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#fff'}}>x</span></div>
                          </div>
            )
           })}
          </div>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onClose}>
            Okay
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Visitsmodal;