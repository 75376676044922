import Container from 'react-bootstrap/Container';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from 'react-bootstrap/Button';
import { PlusSquareFill, FiletypeCsv } from 'react-bootstrap-icons';

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Button onClick={ handleClick} className='buttonadd' variant="primary"><FiletypeCsv size={20}/>Export</Button>
  );
};

function Table({title, data, columns, onClickAdd = null, buttonType = 'add', fluid = false}) {
    const { SearchBar } = Search;
  return (
    <>
    <Container fluid={fluid} className='countercontainer'>
      <div className='tablecontainer'> 
      <ToolkitProvider
                keyField="id"
                data={ data }
                columns={ columns }
                search
                >
                     {
    props => (
             <>
        <div className='tableheader'>
            <h2>{title}</h2>
            {buttonType === 'add' ? <Button onClick={onClickAdd} className='buttonadd' variant="primary"><PlusSquareFill size={20}/> Add</Button> :  <MyExportCSV { ...props.csvProps } />}
            </div>   
           
              <div className='searchbarrow'>
                <div className='showcontainer'>
                {/* <Form.Select size='lg' aria-label="Default select example">
                    <option>Show</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </Form.Select> */}
                </div>
                <div className='searchcontainer'>
                <SearchBar { ...props.searchProps } />
                </div>
                </div> 
                <div className='tableinsidecontainer'>
                <BootstrapTable { ...props.baseProps } keyField='name' data={ data } columns={ columns } pagination={ paginationFactory() } />
                </div>
             </>
           
    )}
             </ToolkitProvider>
      </div>
    </Container>    
    </>
  );
}

export default Table;