import React from "react";


const MinimalLayout = (props) => {

    return (
        <div style={{width: '100%', heght: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff'}}>
          {props.children}
        </div>
    )
}


export default MinimalLayout;