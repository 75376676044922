// action - state management
import { APP_URL } from '../../constants/apis';
import * as actionTypes from '../actions';
import axios from 'axios';

export const createCategory = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createcategory' });
  try {
    await axios.post(`${APP_URL}organization/services/create`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Category created successfully' });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const clearcategorysuccess = () => async (dispatch) => {
  dispatch({type: actionTypes.CLEARSUCCESS})
}

export const updateCategory = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatecategory' });
  try {
    await axios.post(`${APP_URL}organization/services/update`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.UPDATECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Category updated successfully' });

    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const daleteCategory = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'deletecategory' });
  try {
    await axios.post(`${APP_URL}organization/services/delete`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.DELETECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Category deleted successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getCategories = (organization_id, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'getcategories' });
  try {
    await axios.get(`${APP_URL}organization/services/list?organization=${organization_id}`, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETCATEGORYSUCCESS, payload: res.data });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};


