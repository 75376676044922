import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import { useDispatch, useSelector } from 'react-redux';
import { clearlinksuccess, createVisits, getAppointments, getTicketsall, updatestatus } from '../../store/actions/linkActions';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Button } from 'react-bootstrap';
import Statusmodal from '../modals/statusmodal';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';
import jsPDF from 'jspdf';
import { Printer } from 'react-bootstrap-icons';
import Clientmodal from '../modals/detailsmodal';
import Visitsmodal from '../modals/visitsmodal';
import { DateObject } from 'react-multi-date-picker';




function Tickets() {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.link);
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState("not_paid");
  const auth = useSelector((state) => state.auth);
  const [ticket, setTicket] = useState('');
  const [clientdetails, setClientdetails] = useState({});
  const [showdetails, setShowdetails] = useState(false);
  const [showdays, setShowdays] = useState(false);
  const [visitsdetails, setVisitsdetails] = useState({});
  const [choosendates, setChoosendates] = useState([]);
  const organization = auth?.organizations[0]?.organization._id ?? '';
  const rows = Array.isArray(appointments.ticketsall) ? appointments.ticketsall.map(item => ({ ...item, id: item._id, paid: '-' })) : [];

  const onClose = () => {
    setShow(false);
    dispatch(updatestatus(auth.token, ticket, organization, display));
  }


  function convertDates(datesArray, outputFormat) {

    return datesArray?.length > 0 ? datesArray.map(date => moment(`${date.day}/${date?.month?.number}/${date.year}`, 'DD/MM/YYYY').format()) : [];
}


  
  const onCloseVisits = () => {
    const client = visitsdetails?.client?._id;
    const service = visitsdetails?.service?._id;
    const insurance = visitsdetails?.insurance?._id || null;
    const clienttype = visitsdetails?.clienttype;
    const ticket = visitsdetails?._id;
    const status = 'active';
    const dates = convertDates(choosendates, 'DD/MM/YYYY')
    setShowdays(false);
    dispatch(createVisits(auth.token, status, organization, choosendates?.length, client, service, insurance, clienttype, ticket, dates));
    setChoosendates([]);
    setVisitsdetails({});
  }

  useEffect(() => {
    dispatch(getTicketsall(auth.token, organization))
  }, [])

  const onChangeDays = (val) => {
    setChoosendates([...choosendates, val])
  }

  function removedate(object) {
    // Filter out the object that matches the specified conditions
    const updatedDates = choosendates?.filter((c) => 
      !(c.day === object?.day && c.month.number === object?.month?.number && c.year === object?.year)
    );
  
    // Update the state with the filtered array
    setChoosendates(updatedDates);
  }

  useEffect(() => {
    if(appointments.createvisitssuccess){
      const timer = setTimeout(() => {
        dispatch(clearlinksuccess());
      }, 2000);

      return () => clearTimeout(timer);
    }

    // Cleanup the timer if the component unmounts before the timeout
  }, [dispatch, appointments.createvisitssuccess]);

  function capitalizeFirstLetter(str) {
    // Check if the input is not an empty string
    if (str.length === 0) {
      return str;
    }
  
    // Capitalize the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function generatePDF(details) {
    const doc = new jsPDF();
  
    // Add content to the PDF
    doc.setFontSize(25);
    doc.text(`${details.name}`, 105, 30, { align: 'center' });
  
    doc.setFontSize(22);
    doc.text('Queue No.', 105, 60, { align: 'center' });
  
    doc.setFontSize(25);
  //   doc.setFontType('bold');
    doc.text(`${details.number}`, 105, 90, { align: 'center' });
  
    doc.setFontSize(18);
    doc.text(`Arrived ${moment(details.date).format('DD-MM-YYY HH:mm:ss')}`, 105, 120, { align: 'center' });
  
    doc.setFontSize(22);
  //   doc.setFontType('bold');
    doc.text(`${details.service.name}`, 105, 150, { align: 'center' });
  
    doc.setFontSize(16);
    doc.text('There are 0 queuing before you', 105, 180, { align: 'center' });
  
    doc.setFontSize(16);
    doc.text('Your estimated waiting time is 0 minutes', 105, 210, { align: 'center' });
  
    // Save the PDF
    doc.save('queue_ticket.pdf');
  }

 
  const columns = [
    { dataField: 'number', text: 'ID' },
    { dataField: 'name', text: 'Client name' },
    { dataField: 'mobile', text: 'Client phone' },
    { dataField: 'clienttype', text: 'Client type', formatter: (cell, row, rowIndex, extraData) => (
      <span>{capitalizeFirstLetter(row?.clienttype)}</span>
    ), },
    { dataField: 'date', text: 'Date',  formatter: (cell, row, rowIndex, extraData) => (
      <span>{moment(row?.date).format('DD-MM-YYYY HH:mm:ss')}</span>
    ), },
    { dataField: 'insurance', text: 'Insurance',  formatter: (cell, row, rowIndex, extraData) => (
        row.clienttype === 'insurance' ?  <span>{row?.insurance?.name}</span> : '-'
      ), },
    { dataField: 'status', text: 'Status' },
    { dataField: 'paymentstatus', text: 'Payment status', formatter: (cell, row, rowIndex, extraData) => (
        <span>{row.paymentstatus}</span>
      ), },
      { dataField: 'client', text: 'Client details',  formatter: (cell, row, rowIndex, extraData) => (
        <Button onClick={() => {setClientdetails(row.client);setShowdetails(true)} } variant="primary" size='sm'>View</Button>
      ), },
      { dataField: 'client', text: 'Visits',  formatter: (cell, row, rowIndex, extraData) => (
        <Button disabled={row?.visit} onClick={() => {setShowdays(true);setVisitsdetails(row)} } variant="primary" size='sm'>Set visits</Button>
      ), },
    { dataField: 'actions', text: 'Actions',  formatter: (cell, row, rowIndex, extraData) => (
        <Button onClick={() => {setDisplay(row?.paymentstatus);setTicket(row._id);setShow(true)} } variant="primary" size='sm'>Update status</Button>
      ), },
      { dataField: 'actions', text: 'Print',  formatter: (cell, row, rowIndex, extraData) => (
        <Button  onClick={() => {generatePDF(row)} } variant="primary" size='sm'><Printer size={20}/></Button>
      ), },

  ];
  return (
    <>
    <MainLayout>
    <PhotoProvider>
     <Table buttonType='export' columns={columns} data={rows} title={'Tickets'}/>
     </PhotoProvider>
     <Statusmodal onChangeDisplay={(e) => setDisplay(e.target.value)} show={show} display={display} handleClose={() => setShow(false)} onClose={onClose}/>
     <Loadingmodal show={appointments.loading === 'updatestatus'} title='updating status' />
     <Loadingmodal show={appointments.loading === 'createvisits'} title='Adding visits' />
     <Successmodal show={appointments.updatestatussuccess} title='Ticket payment status updated successfully'/>
     <Successmodal show={appointments.createvisitssuccess} title='Visits added successfully' />
     <Clientmodal show={showdetails} title='Client details' details={clientdetails} handleClose={() => setShowdetails(false)}/>
    <Visitsmodal removedate={removedate} choosendates={choosendates}  onChangeDays={onChangeDays} show={showdays} onClose={() => onCloseVisits()} handleClose={() => {setShowdays(false)}}/>
    </MainLayout>
    </>
  );
}

export default Tickets;