import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from 'react-router-dom';




const MainLayout = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        console.log('ken')
        setShow(true)
    };
  
    return (
        <div style={{width: '100%', heght: '100vh'}}>
            <Header handleClose={handleClose} handleShow={handleShow} />
            <Sidebar handleShow={handleShow} handleClose={handleClose} show={show}/>
            <Outlet />
          {props.children}
        </div>
    )
}


export default MainLayout;