import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducar';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import categoryReducer from './categoryReducer';
import counterReducer from './counterReducer';
import staffReducer from './staffReducer';
import linkReducer from './linkReducer';

// Create browser history
export const history = createBrowserHistory();

// ==============================|| COMBINE REDUCER ||============================== //

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  category: categoryReducer,
  counter: counterReducer,
  staff: staffReducer,
  link: linkReducer,
  router: connectRouter(history)
});

const reducer = persistReducer(persistConfig, persistedReducer);

export default reducer;
