import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Person } from 'react-bootstrap-icons';
import { ChevronDown } from 'react-bootstrap-icons';




const Header = ({handleShow}) => {
    return (
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand href="/" style={{color: '#0080FF'}}> <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 60}}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/calls">CALLS</Nav.Link>
                    <Nav.Link href="/tickets">Check-in</Nav.Link>
                    <Nav.Link href="/appointment">Appointment</Nav.Link>
                    <Nav.Link href="/counter">counters</Nav.Link>
                    <Nav.Link href="/category">category</Nav.Link>
                    <Nav.Link href="/insurance">insurance</Nav.Link>
                    <Nav.Link href="/staff">Staff</Nav.Link>
                    <Nav.Link href="/qrcode">Qrcode</Nav.Link>
                    <NavDropdown title="Report" id="collapsible-nav-dropdown">
                      <NavDropdown.Item href="/report/todaysreport">tODAY REPORT</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/report/weeklyreport">WEEKLY REPORT</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/report/monthlyreport">
                        MONTHLY REPORT
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/report/categoryreport">CATEGORY REPORT</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/report/staffperformance">
                         STAFF PERFORMANCE
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Link" id="collapsible-nav-dropdown">
                      <NavDropdown.Item href="/bookingdetails">
                        GENERATE TICKET
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/display">
                        DISPLAY
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav className="navright">
                    <Nav.Link className="profilephoto" onClick={handleShow}><Person size={50}/></Nav.Link>
                    <Nav.Link eventKey={2} onClick={handleShow}>
                      KS <ChevronDown size={20}/>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          );
}


export default Header;