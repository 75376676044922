import Container from 'react-bootstrap/Container';


function Formlayout(props) {
  return (
    <>
    <Container className='countercontainer'>
      <div className='tablecontainer'>
        <div className='tableheader'>
            <h2>{props.title}</h2>
        </div>   
          {props.children}
      </div>
    </Container>    
    </>
  );
}

export default Formlayout;