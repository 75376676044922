import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import Createcategory from '../modals/categorymodal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PencilFill, Trash } from 'react-bootstrap-icons';
import { clearcategorysuccess, createCategory, daleteCategory, getCategories, updateCategory } from '../../store/actions/categoryActions';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';
import Deletemodal from '../modals/deletemodal';


function Categories() {
  const [opendelete, setOpendelete] = React.useState(false);
  const dispatch = useDispatch();
  const [name, setName] = React.useState('');
  const [nameswahili, setNameswahili] = useState('');
  const [acronym, setAcronym] = useState('');
  const [display, setDisplay] = useState('Display on Transfer & Ticket Screen');
  const [selected, setSelected] = React.useState({});
  const [mode, setMode] = React.useState('read');
  const category = useSelector((state) => state.category);
  const auth = useSelector((state) => state.auth);
  const rows = category.categories.map((item, index) => ({ ...item, id: item._id, number: index + 1 }));
  const organization = auth?.organizations[0]?.organization?._id ?? '';
  const [show, setShow] = useState(false);
  const [minutes, setMinutes] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
const columns = [{
  dataField: 'number',
  text: 's No.'
}, {
  dataField: 'name',
  text: 'Category',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.name} {row?.nameswahili ? `/ ${row?.nameswahili}` : null }</span>
  ),
}, {
  dataField: 'acronym',
  text: 'Acronym'
}, {
  dataField: 'minutes',
  text: 'Minutes'
}, {
  dataField: 'display',
  text: 'Display ON'
},{
  dataField: 'action',
  text: 'Action',
  formatter: (cell, row, rowIndex, extraData) => (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
     <PencilFill color='#000' size={15} className='tableicon' onClick={() => handleEdit(row)}/>
     <Trash color='#000' size={15} onClick={() => handleDelete(row)} className='tableicon'/>
    </div>
  ),
},];

const createcategory = () => {
  if(mode === 'write'){
   dispatch(createCategory({organization, name, acronym, display, nameswahili, minutes}, auth.token))
  setShow(false);
  }else if(mode === 'edit'){
   dispatch(updateCategory({id: selected.id, name, acronym, display, nameswahili, minutes}, auth.token))
  setShow(false);
  }
}

const handleCloseDialog = () => {
 setShow(false);
 setOpendelete(false);
}

React.useEffect(() => {
 dispatch(getCategories(organization, auth.token))
}, [organization, auth.token, dispatch])

React.useEffect(() => {
 if(category.createcategorysuccess || category.updatecategorysuccess || category.deletecategorysuccess){
  setTimeout(() => {
    dispatch(clearcategorysuccess());
    dispatch(getCategories(organization, auth.token))
  }, 2000)
 }
}, [category.createcategorysuccess, category.updatecategorysuccess, category.deletecategorysuccess, auth.token, organization, dispatch])

const handleDeleteCategory = () => {
   dispatch(daleteCategory({id: selected.id, name}, auth.token))
  setOpendelete(false);
}

const handleDelete = (row) => {
  setSelected(row);
  setOpendelete(true)
}

const handleEdit = (row) => {
 setMode('edit');
 setName(row.name);
 setAcronym(row.acronym)
 setDisplay(row.display)
 setNameswahili(row?.nameswahili || '')
  setSelected(row);
  setShow(true)
}
  return (
    <>
    <MainLayout>
     <Table columns={columns} data={rows} title={'Categories'}  onClickAdd={() =>{setName(''); setAcronym(''); setMode('write'); setShow(true)}}/>
     <Createcategory mode={mode} handleClose={handleClose} nameswahili={nameswahili}  minutes={minutes} onChangeNameSwahili={(e) => setNameswahili(e.target.value)} onClose={() => createcategory()} handleShow={handleShow} show={show} name={name}  acronym={acronym} display={display} onChangeName={(e) => setName(e.target.value)} onChangeAcronymm={(e) => setAcronym(e.target.value)} onChangeDisplay={(e) => setDisplay(e.target.value)} onChangeMinutes={(e) => setMinutes(e.target.value)}/>
     <Loadingmodal show={['createcategory', 'updatecategory', 'deletecategory'].includes(category.loading)}/>
     <Successmodal title={category.createcategorysuccess ? 'Category Created Successfull' : category.updatecategorysuccess ? 'Category Updated Successfull' : category.deletecategorysuccess ? 'Category Deleted Successfull' : null} show={category.createcategorysuccess || category.updatecategorysuccess || category.deletecategorysuccess} handleClose={() => dispatch(clearcategorysuccess())}/>
     <Deletemodal show={opendelete} onClose={handleDeleteCategory} handleClose={handleCloseDialog}/>
    </MainLayout>
      
    </>
  );
}

export default Categories;