// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  createstaffsuccess: false,
  updatestaffsuccess: false,
  deletestaffsuccess: false,
  loading: false,
  staffs: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.CLEARSUCCESS:
      return {
        ...state,
        createstaffsuccess: false,
        updatestaffsuccess: false,
        deletestaffsuccess: false,
        loading: false,
      }
    case actionTypes.CREATESTAFFSUCCESS:
        return {
          ...state,
          createstaffsuccess: action.payload
        };
    case actionTypes.UPDATESTAFFSUCCESS:
        return {
          ...state,
          updatestaffsuccess: action.payload
        };
    case actionTypes.DELETESTAFFSUCCESS:
        return {
            ...state,
            deletestaffsuccess: action.payload
          };
    case actionTypes.GETSTAFFSUCCESS:
          return {
            ...state,
            staffs: action.payload
          };
    default:
      return state;
  }
};

export default staffReducer;
