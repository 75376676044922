import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Ticketmodal({handleClose, show, link}) {


  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbodyresult'>
          <span className='ticketname'>{link?.ticket?.ticket?.name}</span>
          <span className='queuenumbertitle'>Queue No.</span>
          <span className='queuenumber'>{link?.ticket?.ticket?.number}</span>
          <span className='queuetime'>Arrived {moment(link?.ticket?.ticket?.date).format('DD/MM/YYYY HH:mm:ss')}</span>
          <span className='queuecategory'>{link?.ticket?.ticket?.service?.name}</span>
          <span className='queuewaiting'>There are 0 queuing before you</span>
          <span className='queueestimate'>Your estimated waiting time is 0 min</span>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        <Button  className='modalbuttonresults' variant="primary" onClick={handleClose}>
            Thank you
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Ticketmodal;