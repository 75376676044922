import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

function Timemodal({handleClose, show, setFrom, from, setTo, to, onClose}) {

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>Change Appointment status</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody'>
          <Form className='forminput'>
            <Form.Group className="mb-5 mt-3 forminput" controlId="exampleForm.ControlInput1">
            <Form.Label>Appointment Start Time</Form.Label>
            <DatePicker
              selected={from}
              onChange={setFrom}
              isClearable
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Select a date"
              style={{width: '100%'}}
            />
            </Form.Group>
            <Form.Group className="mb-5 mt-3 forminput" controlId="exampleForm.ControlInput1">
            <Form.Label>Appointment End Time</Form.Label>
            <DatePicker
              selected={to}
              onChange={setTo}
              isClearable
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Select a date"
              style={{width: '100%'}}
            />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onClose}>
            Okay
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Timemodal;