import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import {staffperformance } from '../../store/actions/linkActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';


function Staffperformance() {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.link.staffperformance);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization?._id ?? '';
  const filteredreports = Array.isArray(reports) ? reports.map((rep) => {
    return {
      id: rep._id,
      ...rep
    }
  }) : []
  const token = useSelector((state) => state.auth.token);


  useEffect(() => {
     dispatch(staffperformance(token, organization))
  }, [])

  function formatDuration(seconds) {
    if (seconds < 60) {
        return seconds + " seconds";
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + " minutes";
    } else {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return hours + " hours and " + remainingMinutes + " minutes";
    }
}

const columns = [{
  dataField: 'user',
  text: 'Staff',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.user?.full_name ||''}</span>
  ),

}, {
  dataField: 'visitorsServed',
  text: 'Visitors Served',
},
{
  dataField: 'services',
  text: 'Services',
},
{
  dataField: 'totalServiceTime',
  text: 'Total Service Time',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{formatDuration(row?.totalServiceTime)}</span>
  ),
},
{
  dataField: 'averageServedTime',
  text: 'Average Service Time',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{formatDuration(row?.averageServedTime)}</span>
  ),
}];
  return (
    <>
    <MainLayout>
     <Table buttonType='export' columns={columns} data={filteredreports} title={'Staff Performance'}/>
    </MainLayout>
      
    </>
  );
}

export default Staffperformance;