import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Person } from 'react-bootstrap-icons';
import { Nav } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/authActions";

function OffCanvasExample({ name, handleShow, handleClose, show,  ...props }) {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(logout());
  };
  return (
    <>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
         <div className='sidebartitle'>
            <div className='sidebarprofile'>
            <Person size={30}/>
            </div>
            <div className='sidebarheadercontent'>
                <span className='name-user'>kenneth solomon</span>
                <div className='navactions'><Nav.Link onClick={() => handleLogout()}><span className='logout'>Logout</span></Nav.Link> &nbsp;| &nbsp; <Nav.Link href="/editprofile"><span className='viewprofile'>View Profile</span></Nav.Link>  &nbsp;| &nbsp; <Nav.Link href="/change-password"><span className='changepassword'>Change Password</span></Nav.Link></div>
            </div>
         </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc. */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Sidebar({ name, handleShow, handleClose, show,  ...props }) {
  return (
     <OffCanvasExample name={name} handleShow={handleShow} handleClose={handleClose} show={show}  placement={'end'} />
  );
}

export default Sidebar