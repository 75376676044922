import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

function Loadingmodal({handleClose, show, title = 'updating...'}) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody'>
        <Spinner animation="border" variant="primary" size={'lg'}/>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Loadingmodal;