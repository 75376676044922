import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTickets } from '../../store/actions/linkActions';






const Display = () => {
    const dispatch = useDispatch();
    const tickets = useSelector((state) => state.link.tickets);
    const currentticket = useSelector((state) => state.link.currentticket);
    const filteredtickets = Array.isArray(tickets) ? tickets.filter((tick) => tick?._id !== currentticket?._id) : [];
    const auth = useSelector((state) => state.auth);
    const organization = auth?.organizations[0]?.organization._id ?? '';

    useEffect(() => {
        // Create an interval that dispatches the action every 5 seconds
        const intervalId = setInterval(() => {
          dispatch(getTickets(auth.token, Date.now(), organization));
        }, 5000); // 5000 milliseconds = 5 seconds
    
        // Cleanup the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, [auth.token, organization, dispatch]);

    return (
<div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#fff'}}>
{filteredtickets?.length > 0 && filteredtickets?.map((ticket => {
            return (
<div style={{width: '50%', height: 'calc(100vh / 4)', borderRight: '1px solid #000', borderBottom: '1px solid #000', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
<div style={{width: '40%', borderBottom: '10px solid #000', displa: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
 <span style={{fontSize: 70, color: '#000'}}>{ticket.number}</span>
</div>
</div>
            )}))}
</div>
    )
}


export default Display;