import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers/reducer';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
export const history = createBrowserHistory();
// ==============================|| REDUX - MAIN STORE ||============================== //

let store = createStore(reducer, applyMiddleware(thunk, routerMiddleware(history)));
let persistor = persistStore(store);

// const store = createStore(reducer, applyMiddleware(thunk, routerMiddleware(history)));
// const persister = 'Free';

export { store, persistor };
