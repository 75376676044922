// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  createcountersuccess: false,
  updatecountersuccess: false,
  deletecountersuccess: false,
  loading: false,
  counters: [],
  currentcounter: {},
  insurance: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.CLEARSUCCESS:
      return {
        ...state,
        createcountersuccess: false,
        updatecountersuccess: false,
        deletecountersuccess: false,
        loading: false,
      }
    case actionTypes.CREATECOUNTERSUCCESS:
        return {
          ...state,
          createcountersuccess: action.payload
        };
    case actionTypes.UPDATECOUNTERSUCCESS:
        return {
          ...state,
          updatecountersuccess: action.payload
        };
    case actionTypes.DELETECOUNTERSUCCESS:
        return {
            ...state,
            deletecountersuccess: action.payload
          };
    case actionTypes.GETCOUNTERSUCCESS:
          return {
            ...state,
            counters: action.payload.counters,
            currentcounter: state?.currentcounter?._id ? state?.currentcounter : action.payload.counters?.length > 0 ? action.payload.counters[0] : {}
          };
    case actionTypes.GETINSURANCESUCCESS:
          return {
              ...state,
              insurance: action.payload.insurance,
            };
    case actionTypes.UPDATECURRENTCOUNTER:
      return {
        ...state,
        currentcounter: action.payload
      }
    default:
      return state;
  }
};

export default counterReducer;
