import React, { useState } from 'react';
import Queuelayout from '../../layout/queuelayout';
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Ticketmodal from '../modals/ticketmodal';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { appendticket } from '../../store/actions/linkActions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';




const Bookingdetails = ({...others}) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();


  const handleClose = () => setShow(false);
  
    return (
   <Queuelayout>
    <Container className='queuecontainer'>
       <div className='queuetitleandcat'>
       <h2 className='queuetitle'>Quickline</h2>
       <Formik
        initialValues={{
          phonenumber: '+255',
          name: '',
          address: '',
          age: new Date(),
          submit: null
        }}
        validationSchema={Yup.object().shape({
          phonenumber: Yup.string()
            .required('Phone number is required')
            .test('is-valid-phone', 'Phone number is invalid', (value) => {
              return isValidPhoneNumber(value || '');
            }),
          name: Yup.string().max(255).required('Name is required'),
          address: Yup.string().max(255).required('Address is required'),
          age: Yup.date().required('Age is required')
        })}
        onSubmit={async (values) => {
          const phoneNumberWithoutPlus = values.phonenumber.replace(/\+/g, '');
        //   const removedPrefixPhoneNumber = values.phonenumber.substring(4);
          dispatch(appendticket({mobile: phoneNumberWithoutPlus, name: values.name, address: values.address, age: values.age}))
          window.location.replace(`/queue`);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
          <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}  noValidate onSubmit={handleSubmit} {...others}>
             <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="name"
          aria-label="name"
          type='text'
          aria-describedby="basic-addon1"
          size={'lg'}
          value={values.name}
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={touched.name && errors.name}
        />
          {touched.name && errors.name && (
         <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="address"
          type='text'
          aria-label="address"
          aria-describedby="basic-addon1"
          size={'lg'}
          value={values.address}
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={touched.address && errors.address}
        />
          {touched.address && errors.address && (
         <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
      <DatePicker
              selected={values.age}
              onChange={(date) => setFieldValue('age', date)}
              isClearable
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Select a date"
              style={{width: '100%'}}
            />
        {/* <Form.Control
          type='text'
          placeholder="age"
          aria-label="age"
          aria-describedby="basic-addon1"
          size={'lg'}
          value={values.age}
          name="age"
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={touched.age && errors.age}
        /> */}
          {touched.age && errors.age && (
         <Form.Control.Feedback type="invalid">
              {errors.age}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Phone number"
          type='text'
          aria-label="phone"
          aria-describedby="basic-addon1"
          size={'lg'}
          value={values.phonenumber}
          name="phonenumber"
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={touched.phonenumber && errors.phonenumber}
        />
         {touched.phonenumber && errors.phonenumber && (
         <Form.Control.Feedback type="invalid">
              {errors.phonenumber}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button variant="primary" type="submit" className=' btn-block btn buttoncat mt-3' size='lg'>Submit</Button>
      </Form>
       )}
       </Formik>
       </div>
       <div className='bottonbar'>
         <Button onClick={() => {window.location.replace('/')}} variant="outline-dark" className=' btn-block btn buttonbottom' size='lg'>Home</Button>
      <Button onClick={() => {window.location.replace('/queue')}} variant="outline-dark" className=' btn-block btn buttonbottom' size='lg'>Back</Button>
       </div>  
    </Container>
    <Ticketmodal show={show} handleClose={handleClose} />
   </Queuelayout>
    )
}


export default Bookingdetails;