import Modal from 'react-bootstrap/Modal';
import { CheckCircle } from 'react-bootstrap-icons';
import moment from 'moment';

function Clientmodal({handleClose, show, title, details}) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbodysuccess'>
        <p>Name: {details?.full_name}</p>
        <p>Birth date: {moment(details?.birthdate).format('YYYY-MM-DD')}</p>
        <p>Address: {details?.local_address}</p>
        <p>Phone number: {details?.telephone_number}</p>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default Clientmodal;