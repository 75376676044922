import MainLayout from '../../layout/MainLayout';
import Formlayout from '../formlayout';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { changepassword, clearsuccess } from '../../store/actions/authActions';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';

function Changepassword() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');


  const onSubmit = () => {
    dispatch(changepassword(auth.token, {new_password: newPassword, current_password: currentPassword, auth_user_id: auth.organizations[0]?.user?._id}))
  }
  return (
    <>
    <MainLayout>
   <Formlayout title={'Change password'}>
   <Form className='formlayout'>
   <Row>
   <Col xs={4}>
    <Form.Group value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Old Password</Form.Label>
        <Form.Control type="password" size='md' />
      </Form.Group>
    </Col>
    <Col xs={4}>
    <Form.Group value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" size='md' />
      </Form.Group>
    </Col>
    <Col xs={4}>
    <Form.Group value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)} className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Confirm Password</Form.Label>
        <Form.Control type="password" size='md' />
      </Form.Group>
    </Col>

    <Col xs={12} className='submitbutton'>
    <Button onClick={() => {onSubmit()}} variant="outline-secondary">save changes</Button>
    </Col>
  </Row>
    </Form>

   </Formlayout>
   <Loadingmodal show={auth.loading === 'changepassword'}/>
     <Successmodal title={'Password Changed Successfull'} show={auth.successchangepassword} handleClose={() => dispatch(clearsuccess())}/>
    </MainLayout>
    </>
  );
}

export default Changepassword;