import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import { useDispatch, useSelector } from 'react-redux';
import { todayreports } from '../../store/actions/linkActions';
import moment from 'moment';
import { useEffect } from 'react';


function Todaysreport() {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.link.todayreports);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization?._id ?? '';
  const organizationname = auth?.organizations[0]?.organization ?? '';
  const filteredreports = Array.isArray(reports) ? reports.map((rep) => {
    return {
      id: rep._id,
      organizationname: organizationname?.name?.toUpperCase(),
      ...rep
    }
  }) : []
  const token = useSelector((state) => state.auth.token);


  useEffect(() => {
     dispatch(todayreports(token, organization))
  }, [])

  function formatDuration(seconds) {
    if (seconds < 60) {
        return seconds + " seconds";
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + " minutes";
    } else {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return hours + " hours and " + remainingMinutes + " minutes";
    }
}

const columns = [{
  dataField: 'organizationname',
  text: 'ID'
}, {
  dataField: 'number',
  text: 'Token',
},
{
  dataField: 'date',
  text: 'Date & Time',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{moment(row?.date).format('DD MMMM YYYY HH:mm:ss')}</span>
  ),
},
{
  dataField: 'service',
  text: 'Category',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.service?.name || '-'}</span>
  ),
},
{
  dataField: 'counter',
  text: 'Counter',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.counter?.name || '-'}</span>
  ),
},
{
  dataField: 'name',
  text: 'Name',
},
{
  dataField: 'mobile',
  text: 'Contact',
},
{
  dataField: 'user',
  text: 'Closed By',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.user?.full_name}</span>
  ),
},
{
  dataField: 'servedTime',
  text: 'Response Time',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{formatDuration(row?.servedTime)}</span>
  ),
},
{
  dataField: 'status',
  text: 'Status',
}];
  return (
    <>
    <MainLayout>
     <Table buttonType='export' columns={columns} data={filteredreports} title={"Today's Reports"}/>
    </MainLayout>
      
    </>
  );
}

export default Todaysreport;