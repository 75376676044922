// action - state management
import { APP_URL } from '../../constants/apis';
import * as actionTypes from '../actions';
import axios from 'axios';

export const mobileSend = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'mobileinput' });
  try {
    await axios.post(`${APP_URL}signup/create`, data).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.PHONE, payload: res.data });
      window.location.replace('/auth/verifyphone');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.ERROR, payload: {type: 'mobileinput', message: error?.response?.data || error?.message || 'something went wrong, try again later'} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const login = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'login' });
  try {
    await axios.post(`${APP_URL}signin/authenticate`, data).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.LOGIN, payload: res.data });
     if(res.data.organizations?.length > 0){
      window.location.replace('/dashboard');
     }else{
      window.location.replace('/organization/add');
     }
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.ERROR, payload: {type: 'login', message: error?.response?.data || error?.message || 'something went wrong, try again later'} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const editprofile = (token, data) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'editprofile' });
  try {
    await axios.post(`${APP_URL}profile/edit`, data, {headers}).then(() => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'profile updated successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const changepassword = (token, data) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'changepassword' });
  try {
    await axios.post(`${APP_URL}profile/edit/password`, data, {headers}).then(() => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.SUCCESSCHANGEPASSWORD, payload: true });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.SUCCESSCHANGEPASSWORD, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const clearsuccess = () => async (dispatch) =>{
  dispatch({type: actionTypes.CLEARSUCCESSAUTH});
}

export const logout = () => async (dispatch) => {
  dispatch({ type: actionTypes.LOGOUT });
  window.location.replace('/auth/login');
};

export const verifyphonenumber = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'verifyphone' });
  try {
    await axios.post(`${APP_URL}signup/verify`, data).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.USERINFO, payload: res.data });
      window.location.replace('/auth/personalinformation');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.ERROR, payload: {type: 'verifyphone', message: error?.response?.data || error?.message || 'something went wrong, try again later'} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const register = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'personalinformation' });
  try {
    await axios.post(`${APP_URL}signup/complete_profile`, data).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.TOKEN, payload: res.data });

      // window.location.replace('/organization/add');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.ERROR, payload: {type: 'personalinformation', message: error?.response?.data || error?.message || 'something went wrong, try again later'} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const Addorganization = (data, token) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'organization' });
  console.log(token);
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  try {
    await axios.post(`${APP_URL}organization/create`, data, { headers }).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATEORG, payload: res.data });
      window.location.replace('/');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.ERROR, payload: {type: 'organization', message: error?.response?.data || error?.message || 'something went wrong, try again later'} });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};
