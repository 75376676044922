import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import { categoryreports } from '../../store/actions/linkActions';
import { getCategories } from '../../store/actions/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';


function Categoryreport() {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.link.categoryreports);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization?._id ?? '';
  const organizationname = auth?.organizations[0]?.organization ?? '';
  const category = useSelector((state) => state.category);
  const filteredreports = Array.isArray(reports) ? reports.map((rep) => {
    return {
      id: rep?._id?.service,
      organizationname: organizationname?.name?.toUpperCase(),
      name: category.categories?.find((cat) => cat._id.toString() === rep?._id?.service.toString())?.name,
      service: rep?._id,
      totalServedTime: formatDuration(rep?.totalServedTime),
      missedCount: rep?.missedCount,
      waitingCount: rep?.waitingCount,
      totalRecords: rep?.totalRecords
    }
  }) : []
  const token = useSelector((state) => state.auth.token);

  

  function formatDuration(seconds) {
    if (seconds < 60) {
        return seconds + " seconds";
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + " minutes";
    } else {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return hours + " hours and " + remainingMinutes + " minutes";
    }
}

  useEffect(() => {
    dispatch(getCategories(organization, token))
     dispatch(categoryreports(token))
  }, [])

  const columns = [
    { dataField: 'organizationname', text: 'ID', width: 200 },
    { dataField: 'name', text: 'Name'},
    { dataField: 'totalServedTime', text: 'Total'},
    { dataField: 'totalRecords', text: 'Served', width: 200 },
    { dataField: 'missedCount', text: 'Missed', width: 200 },
    { dataField: 'waitingCount', text: 'Waiting', width: 200 }
  ];
  return (
    <>
    <MainLayout>
     {!category.loading && Array.isArray(category.categories) && <Table buttonType='export' columns={columns} data={filteredreports} title={'Category Report'}/>}
    </MainLayout>
      
    </>
  );
}

export default Categoryreport;