import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Addorganization } from '../../store/actions/authActions';

function Addorg({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);

  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
     
       {/* <h2 className='quicklinetitle'>Quickline</h2> */}
       <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 200}}/>
       <h2 className='quicklineminititle'>Ener your organization details to proceed</h2>
       {customization?.error?.type === 'organization' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
            email: '',
            name: '',
            type: '',
            industry: '',
            phone: '',
            address: '',
            mpesa: '',
            tigopesa: '',
            airtelmoney: '',
            officephonenumber: '',
            halopesa: '',
            submit: null
        }}
        validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          name: Yup.string().max(255).required('Organization is required'),
          type: Yup.string().max(255).required('Organization type is required'),
          industry: Yup.string().max(255).required('industry is required'),
          phone: Yup.string().max(255).required('phone number is required'),
          address: Yup.string().max(255).required('Adress is required'),
          officephonenumber: Yup.string().max(255).required('phone number is required'),
        })}
        onSubmit={async (values) => {
            dispatch(
              Addorganization(
                {
                  name: values.name,
                  email: values.email,
                  type: values.type,
                  industry: values.industry,
                  phone: values.phone,
                  address: values.address,
                  auth_user_id: auth?.userinfo?.user_id,
                  officephonenumber: values.officephonenumber,
                  payment_methods: JSON.stringify([{mpesa: values.mpesa}, {airtelmoney: values.airtelmoney}, {tigopesa: values.tigopesa}, {halopesa: values.halopesa} ])
                },
                auth.token
              )
            );
          }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
        <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Organization name"
          aria-label="Organization name"
          type="text"
          value={values.name}
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.name && errors.name}
        />
        {touched.name && errors.name && (
         <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Organization Type"
          aria-label="Organization Type"
          type="text"
          value={values.type}
          name="type"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.type && errors.type}
        />
        {touched.type && errors.type && (
         <Form.Control.Feedback type="invalid">
              {errors.type}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Email Address"
          aria-label="email"
          type="email"
          value={values.email}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.email && errors.email}
        />
        {touched.email && errors.email && (
         <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Industry"
          aria-label="Industry"
          type="text"
          value={values.industry}
          name="industry"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.industry && errors.industry}
        />
        {touched.industry && errors.industry && (
         <Form.Control.Feedback type="invalid">
              {errors.industry}
            </Form.Control.Feedback>
        )}
      </InputGroup>
     <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Phone number"
          aria-label="phonenumber"
          type="text"
          value={values.phone}
          name="phone"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.phone && errors.phone}
        />
        {touched.phone && errors.phone && (
         <Form.Control.Feedback type="invalid">
              {errors.phone} 
            </Form.Control.Feedback>
        )}
      </InputGroup>

      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Address"
          aria-label="address"
          type="text"
          value={values.address}
          name="address"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.address && errors.address}
        />
        {touched.address && errors.address && (
         <Form.Control.Feedback type="invalid">
              {errors.address} 
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Office phone number (for notifications)"
          aria-label="Office phone number"
          type="text"
          value={values.officephonenumber}
          name="officephonenumber"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.officephonenumber && errors.officephonenumber}
        />
        {touched.officephonenumber && errors.officephonenumber && (
         <Form.Control.Feedback type="invalid">
              {errors.officephonenumber} 
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Mpesa Lipa namba"
          aria-label="mpesa"
          type="text"
          value={values.mpesa}
          name="mpesa"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.mpesa && errors.mpesa}
        />
        {touched.mpesa && errors.mpesa && (
         <Form.Control.Feedback type="invalid">
              {errors.mpesa} 
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Tigopesa Lipa namba"
          aria-label="tigopesa"
          type="text"
          value={values.tigopesa}
          name="tigopesa"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.tigopesa && errors.tigopesa}
        />
        {touched.tigopesa && errors.tigopesa && (
         <Form.Control.Feedback type="invalid">
              {errors.tigopesa} 
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Airetelmoney Lipa namba"
          aria-label="airtelmoney"
          type="text"
          value={values.airtelmoney}
          name="airtelmoney"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.airtelmoney && errors.airtelmoney}
        />
        {touched.airtelmoney && errors.airtelmoney && (
         <Form.Control.Feedback type="invalid">
              {errors.airtelmoney} 
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Halopesa Lipa namba"
          aria-label="halopesa"
          type="text"
          value={values.halopesa}
          name="halopesa"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.halopesa && errors.halopesa}
        />
        {touched.halopesa && errors.halopesa && (
         <Form.Control.Feedback type="invalid">
              {errors.halopesa} 
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <Button disabled={auth.loading === 'organization'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'organization' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : 'Submit'}</Button>
           </Form>
         )}
      </Formik>
       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Addorg;