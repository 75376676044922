import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };


export function Barchart({data}) {
  function formatTime(timeString) {
    // Parse the input time string
    const [hours, minutes] = timeString.split(':').map(Number);
  
    // Check if the input is a valid time
    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return 'Invalid time';
    }
  
    // Determine AM or PM
    const meridiem = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    const formattedHours12 = hours % 12 === 0 ? 12 : hours % 12;
  
    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  
    // Construct the formatted time strings
    const formattedTime12 = `${formattedHours12}:${formattedMinutes} ${meridiem}`;
    const formattedTime24 = `${hours}:${formattedMinutes} ${meridiem}`;
  
    return { formattedTime12, formattedTime24 };
  }
  // Process the data to count hourly visitors
  const hourlyData = Object.keys(data).map((hour) => ({
    hour: `${formatTime(`${hour}:00`)?.formattedTime24}`,
    visitors: data[hour].length,
  }));

  // Chart.js configuration
  const chartData = {
    labels: hourlyData.map((data) => data.hour),
    datasets: [
      {
        label: 'Hourly Visitors',
        data: hourlyData.map((data) => data.visitors),
        backgroundColor: '#25A18E',
        borderColor: '#25A18E',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar options={chartOptions} data={chartData} />;
}