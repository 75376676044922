import * as React from 'react';
import { useSelector } from 'react-redux';
import QRCode from "react-qr-code";
import image from '../../assets/images/qucik.png';
import generatePDF from 'react-to-pdf';
import { Container, Button } from 'react-bootstrap';
import MainLayout from '../../layout/MainLayout';

export default function Qrcode() {
  const auth = useSelector((state) => state.auth);
  const targetRef = React.useRef();
  const organization = auth?.organizations[0]?.organization?._id ?? '';



  return (
    <MainLayout>
       <Container style={{ backgroundColor: '#fff', height: 'calc(100vh - 64px' }} >

<Button onClick={() => generatePDF(targetRef, {filename: 'qrcode.pdf'})} style={{width: '100%', marginTop: 20}}>Download Qr-code</Button>

      <div ref={targetRef} style={{width: '100%', height: 'calc(100vh - 200px)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 200}}/>
            <h1>Scan Qrcode from your phone to book a ticket</h1>
        </div>
      <QRCode value={organization} />
      </div>
    </Container>  
    </MainLayout>
   
  );
}
