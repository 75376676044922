import MainLayout from '../../layout/MainLayout';
import Formlayout from '../formlayout';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { editprofile } from '../../store/actions/authActions';

function Editprofile() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  React.useEffect(() => {
    if(Array.isArray(auth?.organizations) && auth?.organizations[0]){
      setName(auth.organizations[0]?.user?.full_name || auth.organizations[0]?.user?.name);
    setEmail(auth.organizations[0]?.user?.email);
    }
  }, [])

  const onSubmit = () => {
    dispatch(editprofile(auth.token, {full_name: name, email: email, auth_user_id: auth.organizations[0]?.user?._id}))
  }


  return (
    <>
    <MainLayout>
   <Formlayout title={'Edit profile'}>
   <Form className='formlayout'>
   <Row>
    <Col xs={6}>
    <Form.Group onChange={(e) => setName(e.target.value)} value={name} className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" size='md' />
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Contact</Form.Label>
        <Form.Control type="text" size='md' />
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group onChange={(e) => setEmail(e.target.value)} value={email} className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" size='md' />
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Address</Form.Label>
        <Form.Control type="text" size='md' />
      </Form.Group>
    </Col>
    <Col xs={12} className='submitbutton'>
    <Button variant="outline-secondary">save changes</Button>
    </Col>
  </Row>
    </Form>

   </Formlayout>
    </MainLayout>
    </>
  );
}

export default Editprofile;