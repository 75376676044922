import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentcounter } from '../../store/actions/counterActions';

export default function SelectSmall({counter}) {
  const currentcounter = useSelector((state) => state.counter.currentcounter);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const cc = counter?.find((c) => c?._id === event.target.value);
    dispatch(setCurrentcounter(cc));
  };

  return (
    <select id="mySelect"  value={currentcounter?._id} label="Counter" onChange={handleChange}>
    <option value="option1">Choose counter</option>
    {counter?.map((c) => {
        return (
    <option value={c._id}>{c.name}</option>
        )
    })}
    </select>
  );
}
