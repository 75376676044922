import { PencilFill, Trash } from 'react-bootstrap-icons';
import MainLayout from '../../layout/MainLayout';
import Table from '../table';
import React , {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStaff, deleteStaff, clearstaffsuccess } from '../../store/actions/staffActions';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Deletemodal from '../modals/deletemodal';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';


function Staff() {
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization?._id ?? '';
  const staff = useSelector((state) => state.staff);
  const dispatch = useDispatch(); 
  const [opendelete, setOpendelete] = React.useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState('');

  const handleDeleteStaff = () => {
    dispatch(deleteStaff({organization_member_id: selected}, auth.token))
   setOpendelete(false);
  }

  const handleCloseDialog = () => {
    setShow(false);
    setOpendelete(false);
  }

  React.useEffect(() => {
    if(staff.deletestaffsuccess){
     setTimeout(() => {
       dispatch(clearstaffsuccess());
       dispatch(getStaff(organization, auth.token))
     }, 2000)
    }
   }, [staff.deletestaffsuccess, auth.token, organization, dispatch])


  const modifiedData = Array.isArray(staff?.staffs) && staff?.staffs?.map(item => {
    const { _id, ...rest } = item;
    return { id: _id, ...rest };
  }) || [];
  React.useEffect(() => {
    dispatch(getStaff(organization, auth.token));
 }, [auth.token, dispatch, organization])
const columns = [{
  dataField: 'name',
  text: 'Name'
}, {
  dataField: 'user',
  text: 'Username',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.user?.full_name}</span>
  ),
  
}, {
  dataField: 'email',
  text: 'Email'
},
{
  dataField: 'mobile',
  text: 'Contact'
},
{
  dataField: 'address',
  text: 'Address'
},
{
  dataField: 'counter',
  text: 'Counter',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.counter?.name}</span>
  ),
},
{
  dataField: 'service',
  text: 'Category',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{row?.service?.name}</span>
  ),
},
{
  dataField: 'createdAt',
  text: 'Created',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{moment(row?.createdAt).format('DD MMMM YYYY')}</span>
  ),
}, 
{
  dataField: 'action',
  text: 'Action',
  formatter: (cell, row, rowIndex, extraData) => (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
     <NavLink to={`/staff/edit?name=${row?.name}&member=${row?.id}&email=${row?.email}&mobile=${row?.mobile}&address=${row?.address}&counter=${row?.counter?._id}&service=${row?.service?._id}&role=${row?.role}`}><PencilFill color='#000' size={15} className='tableicon'/></NavLink>
     <Trash onClick={() => {setSelected(row?.id);setOpendelete(true)}} color='#000' size={15}  className='tableicon'/>
    </div>
  ),
},
];
  return (
    <>
    <MainLayout>
     <Table columns={columns} data={modifiedData} title={'Staff'} onClickAdd={() => window.location.replace('/staff/create')}/>
     <Deletemodal show={opendelete} onClose={handleDeleteStaff} handleClose={handleCloseDialog}/>
     <Loadingmodal show={['deletestaff'].includes(staff.loading)}/>
     <Successmodal title={'Staff Deleted Successfull'} show={staff.deletestaffsuccess} handleClose={() => dispatch(clearstaffsuccess())}/>
    </MainLayout>
      
    </>
  );
}

export default Staff;