import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function Statusmodal({handleClose, display, show, onChangeDisplay, onClose}) {

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>Change payment status</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody'>
          <Form className='forminput'>
            <Form.Group className="mb-5 mt-3" controlId="exampleForm.ControlInput1">
              <Form.Select value={display}
                onChange={onChangeDisplay}  className='mt-3' size='lg' aria-label="select status">
                <option value="paid">PAID</option>
                <option value="not_paid">NOT PAID</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onClose}>
            Okay
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Statusmodal;