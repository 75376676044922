import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function Createinsurance({handleClose, show, name, onChangeName, onClose, mode}) {


  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>{mode === 'write' ? 'Create' : 'Edit'} Insurance</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody'>
          <Form className='forminput'>
            <Form.Group className="mb-5 mt-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter Insurance Name"
                value={name}
                 onChange={onChangeName}
                autoFocus
                size="lg"
                width={'100%'}
              />
              <div className='checkinput'>
                <Form.Check // prettier-ignore
            type={'checkbox'}
            id={`default-checkbox`}
            label={`Show on display screen`}
            className='mt-3'
          />
              </div>   
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onClose}>
            Okay
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Createinsurance;