// action - state management
import * as actionTypes from '../actions';

export const initialState = {
  isAuthenticated: false,
  loading: false,
  phonenumber: '',
  userinfo: {},
  token: null,
  organizations: '',
  successchangepassword: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.SUCCESSCHANGEPASSWORD:
       return {
        ...state,
        successchangepassword: action.payload
       }
    case actionTypes.CLEARSUCCESSAUTH:
       return {
        ...state,
        successchangepassword: false
       }
    case actionTypes.TOKEN:
      return {
        ...state,
        token: action.payload.auth_token,
        isAuthenticated: true
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        token: action.payload.auth_token,
        organizations: action.payload.organizations,
        isAuthenticated: true,
      };
      case actionTypes.CREATEORG:
        return {
          ...state,
          organizations: action.payload.organizations,
          isAuthenticated: true,
        };
    case actionTypes.LOGOUT:
      return {
        token: null,
        isAuthenticated: false,
        organizations: null,
        userinfo: {},
      };
    case actionTypes.PHONE:
      return {
        ...state,
        phonenumber: action.payload
      };
    case actionTypes.USERINFO:
      return {
        ...state,
        userinfo: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
