import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function Transfermodal({categories, onChangeCategory, category, open, onSubmit, onClose}) {
  return (
    <>
      <Modal show={open} onHide={onClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>Transfer call</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody'>
          <Form className='forminput' style={{marginBottom: 40}}>
              <Form.Select value={category}
                 onChange={(e) => onChangeCategory(e.target.value)}  className='mt-3' size='lg' aria-label="category select">
                <option>Choose category</option>
                {categories?.map((category) => {
                return (
                <option value={category._id}>{category.name}</option>
                )})}
              </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onSubmit}>
            Okay
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={onClose}>
            Cancel
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Transfermodal;