import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



export function Linechart({data}) {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'Number of Tickets',
        data: Object.values(data),
        fill: false,
        borderColor: '#25A18E',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category', // Use 'category' for string labels on x-axis
        position: 'bottom',
        title: {
          display: true,
          text: 'Status',
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Number of Tickets',
        },
      },
    },
  };


  return (
  <Line options={options} data={chartData} />
  )
}
