import MainLayout from '../../layout/MainLayout';
import Formlayout from '../formlayout';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clearstaffsuccess, createStaff } from '../../store/actions/staffActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getCategories } from '../../store/actions/categoryActions';
import { getCounters } from '../../store/actions/counterActions';
import { isValidPhoneNumber } from 'react-phone-number-input';
import React from 'react';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';

function Staffcreate({...others}) {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const counters = useSelector((state) => state.counter);
  const staff = useSelector((state) => state.staff);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization?._id ?? '';


  React.useEffect(() => {
    dispatch(getCategories(organization, auth.token));
    dispatch(getCounters(organization, auth.token));
  }, [organization, auth.token, dispatch])

  React.useEffect(() => {
    if(staff.createstaffsuccess){
     setTimeout(() => {
       dispatch(clearstaffsuccess());
     }, 2000)
    }
   }, [staff.createstaffsuccess, dispatch])
  return (
    <>
    <MainLayout>
   <Formlayout title={'Create Staff'}>
   <Formik
        initialValues={{
          mobile_number: '+255',
          password: '123456',
          email: '',
          name: '',
          role: '',
          service: '',
          counter: '',
          address: '',
          photo: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          mobile_number: Yup.string()
            .required('Phone number is required')
            .test('is-valid-phone', 'Phone number is invalid', (value) => {
              return isValidPhoneNumber(value || '');
            }),
          password: Yup.string().max(255).required('Password is required'),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          name: Yup.string().max(255).required('name is required'),
          address: Yup.string().max(255).required('address is required'),
          role: Yup.string().max(255).required('role is required'),
          counter: Yup.string().max(255).required('counter is required'),
          service: Yup.string().max(255).required('service is required'),
          photo: Yup.mixed().required('Please select a file to upload'),
        })}
        onSubmit={async (values) => {
          console.log(values)
          const removedPrefixPhoneNumber = values.mobile_number.substring(4);
          // console.log({ country_code: '255', organization, mobile_number: removedPrefixPhoneNumber, password: values.password, role: values.role, service: values.service, email: values.email, name: values.name, counter: values.counter })
          dispatch(createStaff({ country_code: '255', staffphoto: values.photo, address: values.address, organization, mobile_number: removedPrefixPhoneNumber, password: values.password, role: values.role, service: values.service, email: values.email, name: values.name, counter: values.counter }, auth.token));
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
   <Form autoComplete='off' className='formlayout' noValidate onSubmit={handleSubmit} {...others}>
   <Row>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control 
        type="text" 
        size='md'
        value={values.name}
        name="name"
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.name && errors.name}
       />
        {touched.name && errors.name && (
         <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Contact</Form.Label>
        <Form.Control 
        type="text" 
        size='md' 
        value={values.mobile_number}
        name="mobile_number"
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.mobile_number && errors.mobile_number}
        />
         {touched.mobile_number && errors.mobile_number && (
         <Form.Control.Feedback type="invalid">
              {errors.mobile_number}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email</Form.Label>
        <Form.Control 
        type="email" 
        size='md'
        value={values.email}
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.email && errors.email}
         />
            {touched.email && errors.email && (
         <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    {/* <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Username</Form.Label>
        <Form.Control 
        type="text" 
        size='md'

         />
      </Form.Group>
    </Col> */}
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Password</Form.Label>
        <Form.Control 
        type="password"
        size='md'
        value={values.password}
        name="password"
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.password && errors.password}
          />
        {touched.password && errors.password && (
         <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Confirm Password</Form.Label>
        <Form.Control 
        type="password" 
        size='md'
        value={values.confirmpassword}
        name="confirmpassword"
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.confirmpassword && errors.confirmpassword}
         />
            {touched.confirmpassword && errors.confirmpassword && (
         <Form.Control.Feedback type="invalid">
              {errors.confirmpassword}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload staff photo</Form.Label>
        <Form.Control onBlur={handleBlur} onChange={(event) => {
                // Set the selected file in Formik state.
                setFieldValue('photo', event.currentTarget.files[0]);
              }} isInvalid={touched.photo && errors.photo} type="file" />
        {touched.photo && errors.photo && (
         <Form.Control.Feedback type="invalid">
              {errors.photo}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    <Col xs={12}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Address</Form.Label>
        <Form.Control 
        type="text" 
        size='md'
        value={values.address}
        name="address"
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.address && errors.address}
         />
            {touched.address && errors.address && (
         <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>User Role</Form.Label>
    <Form.Select isInvalid={touched.role && errors.role} name={"role"}  value={values.role} onChange={handleChange} aria-label="Default role select">
      <option>Select user role</option>
      <option value="counter">Counter</option>
      <option value="staff">Staff</option>
    </Form.Select>
    {touched.role && errors.role && (
         <Form.Control.Feedback type="invalid">
              {errors.role}
            </Form.Control.Feedback>
        )}
    </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Choose counter</Form.Label>
    <Form.Select isInvalid={touched.counter && errors.counter} name={'counter'} value={values.counter} onChange={handleChange} aria-label="Default counter select">
      <option>Select counter</option>
      {Array.isArray(counters?.counters) && counters?.counters?.map((cat) => {
                return (
      <option value={cat._id}>{cat.name}</option>
                )})}
    </Form.Select>
    {touched.counter && errors.counter && (
         <Form.Control.Feedback type="invalid">
              {errors.counter}
            </Form.Control.Feedback>
        )}
    </Form.Group>
    </Col>
    <Col xs={6}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Choose category</Form.Label>
    <Form.Select  isInvalid={touched.service && errors.service}  name={'service'} value={values.service} onChange={handleChange} aria-label="Default category select">
      <option>Select category</option>
      {Array.isArray(category?.categories) && category?.categories?.map((cat) => {
                return (
      <option value={cat._id}>{cat.name}</option>
                )})}
    </Form.Select>
    {touched.service && errors.service && (
         <Form.Control.Feedback type="invalid">
              {errors.service}
            </Form.Control.Feedback>
        )}
    </Form.Group>
    </Col>
    <Col xs={12} className='submitbutton'>
    <Button type="submit" variant="outline-secondary">save changes</Button>
    </Col>
  </Row>
    </Form>
      )}
      </Formik>
      <Loadingmodal show={['createstaff'].includes(staff.loading)}/>
     <Successmodal title={'Staff Created Successfull'} show={category.createstaffsuccess} handleClose={() => dispatch(clearstaffsuccess())}/>
   </Formlayout>
    </MainLayout>
    </>
  );
}

export default Staffcreate;