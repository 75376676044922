import Modal from 'react-bootstrap/Modal';
import { CheckCircle } from 'react-bootstrap-icons';

function Successmodal({handleClose, show, title}) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbodysuccess'>
        <CheckCircle size={100} color='green'/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Successmodal;