import { ExclamationCircle } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Deletemodal({handleClose, show, onClose}) {

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className='modalheader'>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbodydelete'>
         <ExclamationCircle size={100} color='#f8bb86'/>
         <span>You won't be able to revert this!</span>
         <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onClose}>
            YES, DELETE IT!
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={handleClose}>
            NO, CANCEL!
          </Button>
         
        </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Deletemodal;