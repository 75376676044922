import MainLayout from '../../layout/MainLayout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Table from '../table';
import Createcounter from '../modals/countermodal';
import React, { useState } from 'react';
import Loadingmodal from '../modals/loadingmodal';
import Successmodal from '../modals/successmodal';
import { clearcountersuccess, createCounter, daleteCounter, getCounters, updateCounter } from '../../store/actions/counterActions';
import { useDispatch, useSelector } from 'react-redux';
import { PencilFill, Trash } from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Deletemodal from '../modals/deletemodal';


function Counter() {
    const [show, setShow] = useState(false);
    const [opendelete, setOpendelete] = React.useState(false);
    const dispatch = useDispatch();
    const [name, setName] = React.useState('');
    const [selected, setSelected] = React.useState({});
    const [mode, setMode] = React.useState('read');
    const counters = useSelector((state) => state.counter);
    const auth = useSelector((state) => state.auth)
    const rows = counters?.counters?.map((item, index) => ({ ...item, id: item._id, number: index + 1 })) || [];
    const organization = auth?.organizations[0]?.organization?._id ?? '';
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setMode('write');
      setShow(true);
    }

  
const columns = [{
  dataField: 'number',
  text: 's No.'
}, {
  dataField: 'name',
  text: 'Name'
},{
  dataField: 'createdAt',
  text: 'Created',
  formatter: (cell, row, rowIndex, extraData) => (
    <span>{moment(row.createdAt).format('DD MMMM YYYY')}</span>
  ),
},  {
  dataField: 'action',
  text: 'Action',
  formatter: (cell, row, rowIndex, extraData) => (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
     <PencilFill color='#000' size={15} className='tableicon' onClick={() => handleEdit(row)}/>
     <Trash color='#000' size={15} onClick={() => handleDelete(row)} className='tableicon'/>
    </div>
  ),
},];


const handleCloseDialog = () => {
  setShow(false);
  setOpendelete(false);
}

React.useEffect(() => {
  dispatch(getCounters( organization, auth.token))
}, [organization, auth.token, dispatch])

React.useEffect(() => {
  if(counters.createcountersuccess || counters.updatecountersuccess || counters.deletecountersuccess){
    setTimeout(() => {
      dispatch(clearcountersuccess());
      dispatch(getCounters(organization, auth.token))
    }, 2000)
  }
}, [counters.createcountersuccess, counters.updatecountersuccess, counters.deletecountersuccess, auth.token, dispatch, organization])

const handleDeleteCounter = () => {
  dispatch(daleteCounter({id: selected.id, name}, auth.token))
 setOpendelete(false);
}


const createcategory = () => {
  console.log(mode);
   if(mode === 'write'){
    dispatch(createCounter({organization: organization, name}, auth.token))
   setShow(false);
   }else if(mode === 'edit'){
    dispatch(updateCounter({id: selected.id, name}, auth.token))
   setShow(false);
   }
}

const handleDelete = (row) => {
  setSelected(row);
  setOpendelete(true)
}

const handleEdit = (row) => {
  console.log(row);
  setMode('edit');
  setSelected(row);
  setName(row.name);
  setShow(true)
}
  return (
    <>
    <MainLayout>
     <Table columns={columns} data={rows} title={'Counters'} onClickAdd={() =>{setName(''); setMode('write'); setShow(true)}}/>
     <Createcounter mode={mode} handleClose={handleClose} handleShow={handleShow} show={show} name={name} onChangeName={(e) => setName(e.target.value)} onClose={() => createcategory()}/>
     <Loadingmodal show={['createcounter', 'updatecounter', 'deletecounter'].includes(counters.loading)}/>
     <Successmodal title={counters.createcountersuccess ? 'Counter Created Successfull' : counters.updatecountersuccess ? 'Counter Updated Successfull' : counters.deletecountersuccess ? 'Counter Deleted Successfull' : null} show={counters.createcountersuccess || counters.updatecountersuccess || counters.deletecountersuccess} handleClose={() => dispatch(clearcountersuccess())}/>
     <Deletemodal show={opendelete} onClose={handleDeleteCounter} handleClose={handleCloseDialog}/>
    </MainLayout>
      
    </>
  );
}

export default Counter;