// action - state management
import { APP_URL } from '../../constants/apis';
import * as actionTypes from '../actions';
import axios from 'axios';
import { encode } from 'base-64';

function fileToBase64(photoFile) {
  return new Promise((resolve, reject) => {
    if (!photoFile) {
      reject(new Error("No file provided."));
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const base64String = event.target.result;
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(photoFile);
  });
}

export const createStaff = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createstaff' });
  try {
    const instance = axios.create()
    const cloudName = 'dedfrilse';
    const apiKey = '926476138849714';
    const apiSecret = 'RIf1N_n1ugblKCT3UTjSOG0LVm8';
    const credentials = encode(`${apiKey}:${apiSecret}`);
    let staffphoto = " ";
     await fileToBase64(data.staffphoto).then((base64String) => {
      staffphoto = base64String
      // You can now use the base64String as needed.
    })
    .catch((error) => {
      staffphoto = "";
      console.error("Error converting image:", error);
    });
    const blob = await fetch(staffphoto).then((res) => res.blob());
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('upload_preset', 'rx9avpb9');
    const response = await instance.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, formData,  {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    const submitdata = data;
    if(response.data){
      submitdata.staffphoto = response.data.secure_url
    }
    await axios.post(`${APP_URL}organization/user/create`, submitdata, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATESTAFFSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Staff created successfully' });
      window.location.replace('/staff');
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATESTAFFSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updateStaffmember = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createstaff' });
  try {
    console.log('creating staff');
    await axios.post(`${APP_URL}organization/user/update`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATESTAFFSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Staff Edited successfully' });
      window.location.replace('/staff');
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATESTAFFSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const clearstaffsuccess = () => async (dispatch) => {
  dispatch({type: actionTypes.CLEARSUCCESS})
}

export const member = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatestaff' });
  try {
    await axios.post(`${APP_URL}organization/services/update`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.UPDATECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Staff updated successfully' });

    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const deleteStaff = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'deletestaff' });
  try {
    await axios.post(`${APP_URL}organization/user/delete`, data, {headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.DELETESTAFFSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Staff deleted successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.DELETESTAFFSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getStaff = (organization, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'getstaffs' });
  try {
    await axios.post(`${APP_URL}user/organizations/list`, {organization},{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETSTAFFSUCCESS, payload: res.data.organizations });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETSTAFFSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};


