import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function Createcategory({handleClose, show, name, nameswahili, acronym, display, onChangeName, onChangeNameSwahili, onChangeAcronymm, onChangeDisplay, onChangeMinutes, minutes, onClose, mode}) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered 
      aria-labelledby="contained-modal-title-vcenter" style={{paddingBottom: '50px !important'}}>
        <Modal.Header className='modalheader'>
          <Modal.Title>{mode === 'write' ? 'Create' : 'Edit'} Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalbody' style={{minHeight: 500}}>
          <Form className='forminput'>
            <Form.Group className="mb-5 mt-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter Category English Name"
                size="lg"
                width={'100%'}
                value={name}
                onChange={onChangeName}
              />
              <Form.Control
                type="text"
                placeholder="Enter Category Swahili Name"
                size="lg"
                width={'100%'}
                style={{marginTop: 20}}
                value={nameswahili}
                onChange={onChangeNameSwahili}
              />
            <Form.Control
                type="text"
                placeholder="Enter Acronym"
                size="lg"
                width={'100%'}
                className='mt-3'
                value={acronym}
                onChange={onChangeAcronymm}
              />
              <Form.Control
                type="text"
                placeholder="Enter Minutes"
                size="lg"
                width={'100%'}
                className='mt-3'
                value={minutes}
                onChange={onChangeMinutes}
              />
              <Form.Select value={display}
                onChange={onChangeDisplay}  className='mt-3' size='lg' aria-label="category select">
                <option value='Display on Transfer & Ticket Screen'>Display on Transfer & Ticket Screen</option>
                <option value="Ticket Screen">Ticket Screen</option>
                <option value="Transfer Screen">Transfer Screen</option>
              </Form.Select>
            </Form.Group>
          </Form>
          <Modal.Footer className='modalfooter'>
        <Button  className='modalbutton' variant="primary" onClick={onClose} size="lg">
            Okay
          </Button>
          <Button className='modalbutton' variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
         
        </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Createcategory;