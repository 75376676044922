import React, { useRef } from 'react';
import Queuelayout from '../../layout/queuelayout';
import { Power } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { currentTicket, delayticket, getTickets, moveBack, setTimer, skipTransfer, updateTicket, updateTransfer } from '../../store/actions/linkActions';
import moment from 'moment';
import { getCounters } from '../../store/actions/counterActions';
import { getCategories } from '../../store/actions/categoryActions';
import { Button } from 'react-bootstrap';
import SelectSmall from './selectsmall';
import Transfermodal from '../modals/transfermodal';


const audiostring = {
  english: {
    first: 'https://res.cloudinary.com/dedfrilse/video/upload/v1705919753/quicklineaudios/pdwqidyboy0cez2sflt4.m4a',
    1: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/rqw7skpwx4cnxjtjpkqy.m4a',
    2: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227698/quicklineaudios/sox2ejemuwfq5garqjbb.m4a',
    3: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/pdga4fkbr0umnu4mad10.m4a',
    4: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/xagyg5fwr7lraory8iio.m4a',
    5: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/qgezmuzcami0jeywc1uh.m4a',
    6: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227698/quicklineaudios/bv0yjhlcsd3rujhsulxt.m4a',
    7: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/bq4rjzwccs1onuls9lsf.m4a',
    8: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/vo709si8jkqypdcs3zfx.m4a',
    9: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706227697/quicklineaudios/yf2omno5fz43bwvtvym0.m4a',
    last: 'https://res.cloudinary.com/dedfrilse/video/upload/v1705919706/quicklineaudios/y0jwmrnbm33jugeogfvp.m4a'
  },
  kiswahili: {
    first: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228581/quicklineaudios/hderiiirl6hbxb1qlgt6.m4a',
    1: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/cte3oxwyhpydd3zws6e2.m4a',
    2: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/ffc5skieta9ajx5lsaa9.m4a',
    3: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/tzqtqhfjj94l9czjbmda.m4a',
    4: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/mdztuaxibg8ipoj3xjaf.m4a',
    5: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/eih3sosm8zsgrnzzd2zu.m4a',
    6: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/ero43dcihl4mm2n7rjcn.m4a',
    7: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/fpxxgw6lxbodb6lt6lhc.m4a',
    8: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228167/quicklineaudios/z93wjvdnj0s4iw9dbwgo.m4a',
    9: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228168/quicklineaudios/rzjdm2netnbtmvencabv.m4a',
    last: 'https://res.cloudinary.com/dedfrilse/video/upload/v1706228581/quicklineaudios/ahdrnbcbdmnokecgnc34.m4a'
  }
}

const Call = () => {
    const dispatch = useDispatch();
    const tickets = useSelector((state) => state.link.tickets);
    const currentticket = useSelector((state) => state.link.currentticket);
    const filteredtickets = Array.isArray(tickets) ? tickets.filter((tick) => tick?._id !== currentticket?._id) : [];
    const isRunning = useSelector((state) => state.link.isRunning);
    const link = useSelector((state) => state.link);
    const timer = useSelector((state) => state.link.timer);
    const auth = useSelector((state) => state.auth);
    const organization = auth?.organizations[0]?.organization._id ?? '';
    const counter = useSelector((state) => state.counter.counters);
    const currentcounter = useSelector((state) => state.counter.currentcounter);
    const category = useSelector((state) => state.category.categories);
    const [choosencategory, setChoosencategory] = useState('');
    const [open, setOpen] = useState(false);
    const [currentTime, setCurrentTime] = useState(getFormattedTime());
    const audioRef = useRef(null);
    const [isPlaying, setPlaying] = useState(false);
    const [startPlaying, setStartPlaying] = useState(false);

    function trimstring (originalString) {
let trimmedString;
if (originalString && originalString[3] === '0') {
  trimmedString = originalString.slice(4);
} else if(originalString){
  trimmedString = originalString.slice(3);
}else {
  trimmedString = '1'
}
return trimmedString
    }

  const dynamicString = trimstring(currentticket?.number); // Replace with your dynamic string
  const counterString = currentcounter?.name?.replace(/^0+|[^0-9]+/g, '') || '1';
  const language = currentticket?.language || 'kiswahili'; // Replace with 'kiswahili' if needed

  // Create an array of audio files based on the selected language
  const audioFiles = [
    audiostring[language].first,
    ...dynamicString.split('').map(char => audiostring[language][char]),
    audiostring[language].last,
    ...counterString.split('').map(char => audiostring[language][char])
  ];

  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);

  const handleAudioEnd = () => {
    // Check if there are more audio files to play
    if (currentAudioIndex < audioFiles.length - 1) {
      setCurrentAudioIndex(prevIndex => prevIndex + 1);
      audioRef.current.src = audioFiles[currentAudioIndex + 1];
      audioRef.current.play();
    } else {
      setStartPlaying(false);
    }
  };

  useEffect(() => {
   if(currentticket?.number){
    handleButtonClick()
   }
  }, [currentticket])

  const handleButtonClick = () => {
    setStartPlaying(true);
    setCurrentAudioIndex(0);
    audioRef.current.src = audioFiles[0];
    audioRef.current.play();
  };
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime(getFormattedTime());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  
  
    function getFormattedTime() {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const now = new Date();
      const dayOfWeek = daysOfWeek[now.getDay()];
      const day = now.getDate().toString().padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const year = now.getFullYear();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
  
      return `${dayOfWeek} ${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
  
    useEffect(() => {
      let intervalId;
  
      if (isRunning) {
        intervalId = setInterval(() => {
          dispatch(setTimer(timer));
        }, 1000);
      }
  
      return () => {
        clearInterval(intervalId);
      };
    }, [isRunning]);
  
      const onChangeCategory = (val) => {
        setChoosencategory(val);
      }
  
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
  
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };
  
    useEffect(() => {
      dispatch(getTickets(auth.token, Date.now(), organization));
      dispatch(getCounters(organization, auth.token ));
      dispatch(getCategories(organization, auth.token));
    }, [])
    
  
    // useEffect(() => {
    //   if(currentticket?._id){
    //     dispatch(getTickets(auth.token, Date.now()));
    //   }
    // }, [currentticket])
  
    const onPressNext = () => {
      if(!currentticket?._id && tickets?.length > 0){
        dispatch(currentTicket(tickets[0]));
      }else if(currentticket?._id && tickets?.length > 0){
        dispatch(updateTicket(currentticket?._id, timer, currentcounter?._id, auth.organizations[0]?.user?._id || auth.organizations[0]?._id, auth.token, organization, tickets))
      }
    }
  
    const SubmitTransfer = () => {
      setOpen(false);
        dispatch(updateTransfer(currentticket?._id,  currentcounter?._id, choosencategory, auth.organizations[0]?.user?._id,  auth.token, organization))
    }

    const SubmitDelay = () => {
      setOpen(false);
        dispatch(delayticket(currentticket?._id,  currentcounter?._id, currentticket?.service?._id, auth.organizations[0]?.user?._id,  auth.token, organization))
    }
  
    const SubmitSkip = () => {
        dispatch(skipTransfer(currentticket?._id,  currentcounter?._id, auth.organizations[0]?.user?._id,  auth.token, organization))
    }
  
    const SubmitMove = () => {
      dispatch(moveBack())
    }
  
  
    const onPressTransfer = () => {
      setChoosencategory(currentticket?._id);
      setOpen(true)
    }
  
    const onClose = () => {
      setOpen(false);
    }



    return (
   <Queuelayout>
      {/* Create an invisible audio element */}
      <audio ref={audioRef} style={{ display: 'none' }} onEnded={handleAudioEnd} />
      {/* Your other components */}
    <div  className='callcontainer'>
        <div className='callheader'>
            <h2>Quickline</h2>
            <div className='callheadercontent'>
                <div className='callheaderselectcontainer'>
                    <span>Counter:</span>
               <SelectSmall counter={counter}/>
                </div>
                <div className='callheadernamecontainer'>
                    <span>{auth?.organizations[0]?.user?.full_name}</span>
                </div>
                <div className='callheaderwatchcontainer'>
                    <span>{currentTime}</span>
                </div>
                <div onClick={() => window.location.replace('/')} style={{ cursor: 'pointer' }} className='callheaderswitchcontainer'>
                   <Power size={20} color='#fff'/>
                </div>
            </div>
        </div>
        <div className='callmain'>
        <Row>
        <Col xs={12} md={6} className='currentticketcontainer'>
        {currentticket?._id ? (
            <>
             <h3 className='currentsaving'>Currently serving</h3>
              <span className='savingqueue' >Queue Number</span>
              <span className='ticketnumber'>{currentticket?.number}</span>
              <span className='servingtime'>Serving time</span>
              <span className='timeer'>{formatTime(timer)}</span>
              <span className='service'>{currentticket?.service?.name}</span>
              <span className='ticketmname'>{currentticket.name}</span>
              <span className='mobile'>+{currentticket.mobile}</span>
              <span className='countername'>Counter: {currentcounter?.name}</span>
              <span className='datename'>Issue Date: {moment(currentticket.date).format('DD-MM-YYYY HH.mm.ss')}</span>
            </>  ) :  <h2>No Queue</h2>}
        </Col>
        <Col xs={12} md={2} className='actionscontainer'>
        <Button onClick={() => onPressNext()} disabled={filteredtickets.length <= 0 && !currentticket?.number} variant="primary" className='callactionbutton'>Next</Button>
        <Button onClick={() => onPressTransfer()} variant="primary" className='callactionbutton'>Transfer</Button>
        <Button onClick={() => SubmitSkip()} variant="primary" className='callactionbutton'>Skip</Button>
        <Button variant="primary" className='callactionbutton' onClick={handleButtonClick} disabled={startPlaying || !currentticket?.number}>Recall</Button>
        <Button onClick={() => SubmitDelay()} variant="primary" className='callactionbutton'>Delay</Button>
        <Button onClick={() => SubmitMove()} variant="primary" className='callactionbutton'>Move Back</Button>
        </Col>
        <Col xs={12} md={4} className='ticketscontainer'>
         <div className='waitingcount'>
         <span>0 visitors are waiting</span>
         </div>
         {filteredtickets?.length > 0 && filteredtickets?.map((ticket => {
            return (
         <div className='ticket'>
          <span className='ticketservice'>{ticket.service.name}---</span>
          <span className='ticketnameandcode'>{ticket.name} ({ticket.number})</span>
          <span className='ticketphone'>{ticket.mobile}</span>
          <span className='ticketdate'>Issue Date: {moment(ticket?.date).format('DD/MM/YYYY HH:mm:ss')}</span>
         </div>
            )}))}
        </Col>
      </Row>

        </div>
        <div className='callfooter'>
         <span>Missed Queue: </span>
        </div>
    </div>
    <Transfermodal categories={category} onChangeCategory={onChangeCategory} category={choosencategory} open={open} onSubmit={() => SubmitTransfer()} onClose={onClose}/>
   </Queuelayout>
    )
}


export default Call;