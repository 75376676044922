// action - state management
import { APP_URL } from '../../constants/apis';
import * as actionTypes from '../actions';
import axios from 'axios';

export const createTicket = (token, data) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createticket' });
  try {
    await axios.post(`${APP_URL}tickets/create`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.CREATETICKETSUCCESS, payload: res.data });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Ticket created successfully' });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATETICKETSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const appendticket = (params) => (dispatch) => {
    dispatch({type: actionTypes.APPENDTICKET, payload: params});
}

export const currentTicket = (params) => (dispatch) => {
  dispatch({type: actionTypes.CURRENTTICKET, payload: params});
}

export const setTimer = (params) => (dispatch) => {
  dispatch({type: actionTypes.SETTIMER, payload: params});
}

export const clearcurrent = (params) => (dispatch) => {
  dispatch({type: actionTypes.CLEARCURRENT, payload: params});
}

export const moveBack = () => (dispatch) => {
  dispatch({type: actionTypes.CLEARTICKET, payload: {}});
}

export const clearlinksuccess = () => async (dispatch) => {
  dispatch({type: actionTypes.CLEARLINKSUCCESS})
}

export const updateTicket = (ticket, timer, counter, user, token, organization, nextticket) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updateticket' });
  dispatch({type: actionTypes.UPDATETIMER, payload: false});
  try {
    await axios.post(`${APP_URL}tickets/done`, {ticket: ticket, servedTime: timer, counter: counter, user: user}, {headers}).then(async (res) => {
      console.log(res.data);
     if(nextticket?.length > 1){
      await axios.get(`${APP_URL}tickets/list?date=${Date.now()}&organization=${organization}`,{headers}).then((res) => {
        dispatch({ type: actionTypes.LOADING, payload: false });
        dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: res.data.tickets });
        // window.location.replace('/categories');
      });
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.CURRENTTICKET, payload: nextticket[1]});
     }else if(nextticket?.length === 1){
      await axios.get(`${APP_URL}tickets/list?date=${Date.now()}&organization=${organization}`,{headers}).then((res) => {
        dispatch({ type: actionTypes.LOADING, payload: false });
        dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: res.data.tickets });
        // window.location.replace('/categories');
      });
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.CLEARTICKET, payload: {}});
     }
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Ticket updated successfully' });

    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updateTransfer = (ticket, counter, category, user, token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatetransfer' });
  dispatch({type: actionTypes.UPDATETIMER, payload: false});
  try {
    await axios.post(`${APP_URL}tickets/transfer`, {ticket: ticket,  counter: counter, service: category, user: user }, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.CLEARTICKET, payload: {}});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Ticket transfered successfully' });

    });
    await axios.get(`${APP_URL}tickets/list?date=${Date.now()}&organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const delayticket = (ticket, counter, category, user, token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatetransfer' });
  dispatch({type: actionTypes.UPDATETIMER, payload: false});
  try {
    await axios.post(`${APP_URL}tickets/delay`, {ticket: ticket,  counter: counter, service: category, user: user }, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.CLEARTICKET, payload: {}});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Ticket delayed successfully' });

    });
    await axios.get(`${APP_URL}tickets/list?date=${Date.now()}&organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const skipTransfer = (ticket, counter, user, token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatetransfer' });
  dispatch({type: actionTypes.UPDATETIMER, payload: false});
  try {
    await axios.post(`${APP_URL}tickets/skip`, {ticket: ticket,  counter: counter, user: user }, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.CLEARTICKET, payload: {}});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Ticket skipped successfully' });

    });
    await axios.get(`${APP_URL}tickets/list?date=${Date.now()}}&organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updatestatus = (token, ticket, organization, status) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatestatus' });
  try {
    await axios.post(`${APP_URL}tickets/update`, {ticket, status  }, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: true});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Ticket updated successfully' });

    });
    await axios.get(`${APP_URL}tickets/listall?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
   setTimeout(() => {
    window.location.reload();
   }, 2000)
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: false});
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const createVisits = (token, status, organization, days, client, service, insurance, clienttype, ticket, dates) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'createvisits' });
  try {
    await axios.post(`${APP_URL}organization/visits`, {organization, status, days, client, service, insurance, clienttype, ticket, dates  }, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.CREATEVISITSSTATUS, payload: true});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Visits set successfully' });

    });
    await axios.get(`${APP_URL}tickets/listall?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
   setTimeout(() => {
    window.location.reload();
   }, 2000)
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: false});
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updatestatusappointment = (token, ticket, organization, status) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatestatus' });
  try {
    await axios.post(`${APP_URL}appointments/update`, {ticket, status}, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: true});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Appointment updated successfully' });

    });
    await axios.get(`${APP_URL}appointments/listall?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
    setTimeout(() => {
      window.location.reload();
     }, 2000)
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: false});
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updatedateappointment = (token, ticket, organization, start_time, end_time) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatestatus' });
  try {
    await axios.post(`${APP_URL}appointments/updatestatus`, {ticket, end_time, start_time}, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: true});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Appointment updated successfully' });

    });
    await axios.get(`${APP_URL}appointments/listall?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
    setTimeout(() => {
      window.location.reload();
     }, 2000)
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: false});
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const updatedateappointment2 = (token, ticket, organization, start_time, end_time) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'updatestatus' });
  try {
    await axios.post(`${APP_URL}appointments/updatedate`, {ticket, end_time, start_time, from: 'doctor'}, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: true});
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Appointment updated successfully' });

    });
    await axios.get(`${APP_URL}appointments/listall?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
    setTimeout(() => {
      window.location.reload();
     }, 2000)
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({type: actionTypes.TICKETUPDATESTATUS, payload: false});
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const deleteStaff = (data, token) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'deletestaff' });
  try {
    await axios.post(`${APP_URL}organization/services/delete`, data, {headers}).then((res) => {
      console.log(res.data);
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.DELETECATEGORYSUCCESS, payload: true });
      dispatch({ type: actionTypes.SUCCESSMESSAGE, payload: 'Counter deleted successfully' });
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.CREATECATEGORYSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};


export const getTickets = (token, date, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'gettickets' });
  try {
    await axios.get(`${APP_URL}tickets/list?date=${date}&organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getAppointments = (organization) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING, payload: 'appointments' });
  try {
    await axios.get(`${APP_URL}appointments/listall?organization=${organization}`).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETAPPOINTMENTSSUCCESS, payload: res.data.appointments });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETTICKETSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const getTicketsall = (token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'appointments' });
  try {
    await axios.get(`${APP_URL}tickets/listall?organization=${organization}`, {headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: res.data.tickets });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETTICKETSSUCCESSALL, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const todayreports = (token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'gettodayreports' });
  try {
    await axios.get(`${APP_URL}tickets/todaysreport?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETTODAYREPORTSSUCCESS, payload: res.data.report });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETTODAYREPORTSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const monthreports = (token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'gettodayreports' });
  try {
    await axios.get(`${APP_URL}tickets/monthsreport?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETMONTHREPORTSSUCCESS, payload: res.data.report });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETMONTHREPORTSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const weeklyreports = (token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'getweeklyreports' });
  try {
    await axios.get(`${APP_URL}tickets/weeklyreport?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETWEEKREPORTSSUCCESS, payload: res.data.report });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETWEEKREPORTSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const staffperformance = (token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  dispatch({ type: actionTypes.LOADING, payload: 'gettodayreports' });
  try {
    await axios.get(`${APP_URL}tickets/staffperformance?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETSTAFFPERFORMANCESSUCCESS, payload: res.data });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETSTAFFPERFORMANCESSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};

export const categoryreports = (token, organization) => async (dispatch) => {
  const headers = {
    'auth-token': token,
    'Content-Type': 'application/json'
  };
  console.log(token)
  dispatch({ type: actionTypes.LOADING, payload: 'gettodayreports' });
  try {
    await axios.get(`${APP_URL}tickets/categoryreport?organization=${organization}`,{headers}).then((res) => {
      dispatch({ type: actionTypes.LOADING, payload: false });
      dispatch({ type: actionTypes.GETCATEGORYREPORTSSUCCESS, payload: res.data.report });
      // window.location.replace('/categories');
    });
  } catch (error) {
    console.log(error?.response?.data || error);
    dispatch({ type: actionTypes.GETCATEGORYREPORTSSUCCESS, payload: false });
    dispatch({ type: actionTypes.LOADING, payload: false });
  }
};


