import React from 'react';
import Queuelayout from '../../layout/queuelayout';
import { Button, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../store/actions/categoryActions';
import { clearlinksuccess, createTicket } from '../../store/actions/linkActions';
import { v4 as uuidv4 } from 'uuid';
import Ticketmodal from '../modals/ticketmodal';
import Loadingmodal from '../modals/loadingmodal';
import jsPDF from 'jspdf';
import moment from 'moment';

function generatePDF(details) {
    const doc = new jsPDF();
  
    // Add content to the PDF
    doc.setFontSize(25);
    doc.text(`${details.name}`, 105, 30, { align: 'center' });
  
    doc.setFontSize(22);
    doc.text('Queue No.', 105, 60, { align: 'center' });
  
    doc.setFontSize(25);
  //   doc.setFontType('bold');
    doc.text(`${details.number}`, 105, 90, { align: 'center' });
  
    doc.setFontSize(18);
    doc.text(`Arrived ${moment(details.date).format('DD-MM-YYY HH:mm:ss')}`, 105, 120, { align: 'center' });
  
    doc.setFontSize(22);
  //   doc.setFontType('bold');
    doc.text(`${details.service.name}`, 105, 150, { align: 'center' });
  
    doc.setFontSize(16);
    doc.text('There are 0 queuing before you', 105, 180, { align: 'center' });
  
    doc.setFontSize(16);
    doc.text('Your estimated waiting time is 0 minutes', 105, 210, { align: 'center' });
  
    // Save the PDF
    doc.save('queue_ticket.pdf');
  }





const Queue = () => {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category);
  const link = useSelector((state) => state.link);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization?._id ?? '';
  const organizationdata = auth?.organizations[0]?.organization ?? '';
  const [uniqueId, setUniqueId] = useState(null);

  useEffect(() => {
    dispatch(getCategories(organization, auth.token));
    dispatch(clearlinksuccess());
  }, [auth.token, organization, dispatch])

  useEffect(() => {
    // Generate a unique ID using UUID v4
    const generatedUniqueId = uuidv4();
    
    // Update the state with the generated unique ID
    setUniqueId(generatedUniqueId);
  }, []); // E


  const handleClick = (cat) => {
    dispatch(createTicket(auth.token, {service: cat._id, ...link.unsubmittedticket, date: Date.now(),client_id: uniqueId, organization, insurancephoto: '', clienttype: 'walk-in',officephonenumber: organizationdata?.officephonenumber, language: 'kiswahili'  }))
  }


  useEffect(() => {
    if(link?.ticket?.ticket){
     generatePDF(link?.ticket?.ticket);
    }
   }, [link]);

    return (
   <Queuelayout>
    <Container className='queuecontainer'>
       <div className='queuetitleandcat'>
       <h2 className='queuetitle'>Quickline</h2>
       {category.categories.map((cat) => {
        return (
       <Button key={cat?._id} onClick={() => handleClick(cat)} variant="outline-dark" className=' btn-block btn buttoncat mt-3' size='lg'>{cat.name}</Button>
              )
        })}
       </div>
       <Button onClick={() => {window.location.replace('/')}} variant="outline-dark" className=' btn-block btn bottombutton' size='lg'>Home</Button>
    </Container>
    <Ticketmodal show={link.createticketsuccess} handleClose={() => {dispatch(clearlinksuccess()); window.location.replace('/bookingdetails')}} link={link} />
    <Loadingmodal show={link.loading === 'createticket'} title={'Creating ticket'}/>
   </Queuelayout>
    )
}


export default Queue;