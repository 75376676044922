import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Piechart } from './piechart';
import MainLayout from '../../layout/MainLayout';
import { Linechart } from './linechart';
import { Barchart } from './barchart';
import moment from "moment-timezone";
import { useDispatch, useSelector } from 'react-redux';
import { getTicketsall } from '../../store/actions/linkActions';




const Dashboard =  () => {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.link);
  const auth = useSelector((state) => state.auth);
  const organization = auth?.organizations[0]?.organization._id ?? '';
  const rows = Array.isArray(appointments.ticketsall) ? appointments.ticketsall.map(item => ({ ...item, id: item._id, paid: '-' })) : [];


  useEffect(() => {
    dispatch(getTicketsall(auth.token, organization))
  }, [])

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;


const groupAppointmentsTicketsHourly = ({ entries = [] }) => {
  console.log(entries);
    const entriesGroupedHourly = entries.reduce((group, entry) => {
        const currentGroup = group || {};
        const entryTime = moment.utc(entry.start_time || entry.date).tz(tz);
        const entryHour = entryTime.format("HH");
        if (currentGroup[entryHour]) {
            const thisHourEntries = currentGroup[entryHour];
            thisHourEntries.push(entry);
            currentGroup[entryHour] = thisHourEntries;
        } else {
            currentGroup[entryHour] = [entry];
        }
        return currentGroup;
    }, {});
    return entriesGroupedHourly;
}


const groupTicketsByStatus = ({ tickets = [] }) => {
    const entriesGroupedHourly = tickets.reduce((group, ticket) => {
        const currentGroup = group || {};
        const ticketStatus = ticket.status;
        if (currentGroup[ticketStatus]) {
            currentGroup[ticketStatus] = currentGroup[ticketStatus] + 1;
        } else {
            currentGroup[ticketStatus] = 1;
        }
        return currentGroup;
    }, {});
    return entriesGroupedHourly;
}

    return (
        <MainLayout>
        <Container>
        <Row>
          {/* <Col xs={12} md={4} className='chartcontainer m-1'  >
            <Piechart/>
          </Col>
          <Col xs={12} md={6} className='chartcontainer m-1' >
            <Linechart/>
          </Col> */}
          <Col xs={12} md={12} className='chartcontainer m-3' >
           {rows?.length > 0 && <Linechart data={groupTicketsByStatus({tickets: rows})}/>}
          </Col>
          <Col xs={12} md={12} className='chartcontainer m-3' >
            <Barchart data={groupAppointmentsTicketsHourly({entries: rows})}/>
          </Col>
          {/* <Col xs={12} md={12} className='chartcontainer m-3' >
            <Linechart/>
          </Col>
          <Col xs={12} md={12} className='chartcontainer m-3' >
            <Linechart/>
          </Col> */}
        </Row>
      </Container>
      </MainLayout>
    )
}



export default Dashboard;