import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MinimalLayout from '../../layout/MinimalLayout';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../store/actions/authActions';

function Personalinformation({...others}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customization = useSelector((state) => state.customization);

  return (
    <>
    <MinimalLayout>
    <Container className='logincontainer'>
       <div className='queuetitleandcat'>
     
       {/* <h2 className='quicklinetitle'>Quickline</h2> */}
       <img src='https://res.cloudinary.com/dedfrilse/image/upload/v1700154718/quickline/dxiyyecgjittbj8yzrwl.png' style={{width: 200}}/>
       <h2 className='quicklineminititle'>Enter your personal information to complete registration</h2>
       {customization?.error?.type === 'personalinformation' && <Alert variant={'danger'}>
          {customization?.error?.message?.message}
        </Alert>}
       <Formik
        initialValues={{
            email: '',
            password: '',
            fname: '',
            lname: '',
            policy: '',
            submit: null
        }}
        validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            password: Yup.string().max(255).required('Password is required'),
            fname: Yup.string().max(255).required('First name is required'),
            lname: Yup.string().max(255).required('Last name is required'),
            policy: Yup.string().max(255).required('please read and accept the privacy policy')
        })}
        onSubmit={async (values) => {
            dispatch(
              register({
                user_id: auth.userinfo.user_id,
                full_name: `${values.fname} ${values.lname}`,
                password: values.password,
                email: values.email,
                user_type: 'organization'
              })
            );
          }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
           <Form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} noValidate onSubmit={handleSubmit} {...others}>
        <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="First name"
          aria-label="firstname"
          type="text"
          value={values.fname}
          name="fname"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.fname && errors.fname}
        />
        {touched.fname && errors.fname && (
         <Form.Control.Feedback type="invalid">
              {errors.fname}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Last name"
          aria-label="lastname"
          type="text"
          value={values.lname}
          name="lname"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.lname && errors.lname}
        />
        {touched.lname && errors.lname && (
         <Form.Control.Feedback type="invalid">
              {errors.lname}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Email"
          aria-label="email"
          type="email"
          value={values.email}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.email && errors.email}
        />
        {touched.email && errors.email && (
         <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
        <Form.Control
          placeholder="Password"
          type='password'
          name="password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label="password"
          aria-describedby="basic-addon1"
          size={'lg'}
          isInvalid={touched.password && errors.password}
        />
          {touched.password && errors.password && (
         <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
        )}
      </InputGroup>
      <InputGroup className="mt-3 bookinginput">
              <Form.Check
          type={'checkbox'}
          id={`policy`}
          name="policy"
          value={values.policy}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label="policy-policy"
          aria-describedby="basic-addon1"
          isInvalid={touched.policy && errors.policy}
          label={(
            <>
              I have read and agree to the{' '}
              <a href="https://quickline.tech" target="_blank">
                privacy policy
              </a>{' '}
              of quickline.tech
            </>
          )}
        />
          {touched.policy && errors.policy && (
         <Form.Control.Feedback type="invalid">
              {errors.policy}
            </Form.Control.Feedback>
        )}
      </InputGroup>

      <Button disabled={auth.loading === 'personalinformation'} type='submit' variant="primary" className=' btn-block btn buttoncat mt-3' size='lg'>{auth.loading === 'personalinformation' ?  <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : 'Submit'}</Button>
           </Form>
         )}
      </Formik>
       </div>
    </Container>
    </MinimalLayout>
      
    </>
  );
}

export default Personalinformation;